<template>
    <p class="h5 mt-4 font-weight-bold">
        {{ __('TITLE.DATA_ENTRY') }}
    </p>
    <div>
        <sub-menu-top-bar
            :menu-items="menuItems"
        />

        <div class="card">
            <div class="card-body">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import __ from '../../utils/translator.js';

import SubMenuTopBar from '../../components/sub-menu-top-bar.vue';

export default {
    components: {
        SubMenuTopBar
    },

    data() {
        return {
            enabled_zootech: 0,
            enabled_health: 0,
            enabled_welfare: 0,
            enabled_environment: 0,
            enabled_economics: 0,
            enabled_governance: 0,
            enabled_product: 0
        }
    },

    computed: {
        menuItems() {
            return [
                {
                    title: __('MENUBAR.ZOOTECH'),
                    onclick: (ev) => this.goto(ev, '/data-entry/zootech'),
                    selected: this.enabled_zootech,
                    mobileIconClass: 'fas fa-fish'
                },
                {
                    title: __('MENUBAR.HEALTH_BIOSECURITY'),
                    onclick: (ev) => this.goto(ev, '/data-entry/health'),
                    selected: this.enabled_health,
                    mobileIconClass: 'fas fa-medkit'
                },
                {
                    title: __('MENUBAR.WELFARE'),
                    onclick: (ev) => this.goto(ev, '/data-entry/welfare'),
                    selected: this.enabled_welfare,
                    mobileIconClass: 'fas fa-hand-holding-heart'
                },
                {
                    title: __('MENUBAR.ENVIRONMENT'),
                    onclick: (ev) => this.goto(ev, '/data-entry/environment'),
                    selected: this.enabled_environment,
                    mobileIconClass: 'fas fa-leaf'
                },
                {
                    title: __('MENUBAR.ECONOMICS'),
                    onclick: (ev) => this.goto(ev, '/data-entry/economics'),
                    selected: this.enabled_economics,
                    mobileIconClass: 'fas fa-dollar-sign'
                },
                {
                    title: __('MENUBAR.GOVERNANCE'),
                    onclick: (ev) => this.goto(ev, '/data-entry/governance'),
                    selected: this.enabled_governance,
                    mobileIconClass: 'fas fa-balance-scale'
                }
            ]
        }
    },

    mounted() {
        this.setEnabledMenuItem();
    },

    beforeUpdate() {
        this.enabled_zootech = 0;
        this.enabled_health = 0;
        this.enabled_welfare = 0;
        this.enabled_environment = 0;
        this.enabled_economics = 0;
        this.enabled_governance = 0;
        this.enabled_product = 0;
        this.setEnabledMenuItem();
    },

    methods: {
        goto(ev, path) { //Programamtic navigation
            ev.preventDefault();
            this.$router.push({ path });
        },

        setEnabledMenuItem() {
            //Set "current" class in the current menu section
            /* SET CURRENT MENU CLASS */
            switch (this.$route.path) {
                case '/data-entry':
                case '/data-entry/zootech':
                    this.enabled_zootech = 1;
                    break;
                case '/data-entry/health':
                    this.enabled_health = 1;
                    break;
                case '/data-entry/welfare':
                    this.enabled_welfare = 1;
                    break;
                case '/data-entry/environment':
                    this.enabled_environment = 1;
                    break;
                case '/data-entry/economics':
                    this.enabled_economics = 1;
                    break;
                case '/data-entry/governance':
                    this.enabled_governance = 1;
                    break;
                case '/data-entry/product':
                    this.enabled_product = 1;
                    break;
            }
        },

        __ //translator
    }
};
</script>